// see https://www.drupal.org/docs/7/api/javascript-api/managing-javascript-in-drupal-7
(function ($) {
  Drupal.behaviors.examplebehavior = {
    attach: function (context, settings) {
      // Code to be run on page load, and
      // on ajax load added here
    }
  };
}(jQuery));
// second method - besides DrupalBehavior
(function ($) {

})(jQuery);
